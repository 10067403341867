<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar
        dark
        dense
        color="brown"
      >
        <h3> Catalogo de pólizas de soporte</h3>
        <v-spacer />
        <v-btn
          small
          class="ma-4"
          color="success"
          @click="consultar"
        >
          Consultar <v-icon>mdi-update</v-icon>
        </v-btn>
        <v-btn
          align="right"
          color="orange"
          dark
          small
          class="ma-2"
          :to="{ name: 'poliza' }"
        >
          Nuevo <v-icon>mdi-update</v-icon>
        </v-btn>
        <v-btn
          align="right"
          class="ma-4"
          small
          color="green"
          dark
        >
          <download-excel
            :data="proyectos"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="CatProyectos.xls"
          >
            Excel
            <v-icon class="ma-1">
              mdi-download
            </v-icon>
          </download-excel>
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-row>
      <v-col>
        <v-card elevation="10">
          <v-subheader class="ma-2">
            <h2 class="ma-4 pt-2 brown--text">
              Polizas de soporte
            </h2>
            <v-spacer />
            <v-col cols="2">
              <v-text-field
                v-model="search"
                class="ma-2"
                append-icon="mdi-magnify"
                label="Filtrar"
                single-line
                hide-details
              />
            </v-col>
          </v-subheader>

          <v-row>
            <v-col>
              <v-data-table
                heigth="350"
                dense
                :headers="headers"
                :items="poliza"
                :search="search"
              >
                <template v-slot:item="{ item }">
                  <tr @dblclick="editar(item)">
                    <td>{{ item.nomcont }}</td>
                    <td>{{ item.nomcli }}</td>
                    <td>{{ item.idproyecto }}</td>
                    <td>{{ item.fechaini }}</td>
                    <td>{{ item.fechafin }}</td>
                    <td>{{ item.factura }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      items: ['Vigentes', 'Vencidas'],
      values: ['Vigentes', 'vencidas'],
      value: null,
      loading : true,
      search: '',
      poliza: [],
      headers: [
        { text: 'Contacto', value: 'nomcont' },
        { text: 'Cliente', value: 'nomcli' },
        { text: 'Proyecto', value: 'idproyecto' },
        { text: 'Fecha Inicio', value: 'fechaini' },
        { text: 'Fecha de termino', value: 'fechafin' },
        { text: 'Factura', value: 'factura' },
      ],
      json_fields: {
        Id: "idcita",
        Nombre: "idcontacto",
        Cliente: "cliente",
        Asunto: "temas",
        Fecha: "fechastart",
        Hora: "hora",
        Lugar: "lugar",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      json_data: [],
    }
  },
  created(){
    this.consultar()
  },

  methods: {
      
    consultar(){
      // limpiar
      this.poliza= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)
      this.loading = true

      this.$http.get("v2/polsop.list" ).then(response=>{
        if (response.data.error===null){
          this.loading = false
          this.poliza = response.data.result

          this.poliza.forEach( element => {
            element.fechastart = element.fechastart.substr(0,10)
            element.hora = element.fechastart.substr(11,17)
          })

          console.log("citas", this.poliza)
        }
          
      }).catch(error=>{
        	console.log(error)
      })
    },
      
    editar(item){
      console.log("Renglonitem", item)
      this.$router.push({name:'cita', params: {cita: item}})
    },

  }
}

</script>